import React from 'react';
import { useClickAway } from 'react-use';
import { Box } from '@mui/material';
import type { ContentfulRichText } from '@models';
import { useIsMobile } from '@hooks/common';
import TooltipDialog from '@components/Common/TooltipDialog';
import TooltipPopover from '@components/Common/TooltipPopover';

interface TooltipV2Props {
    children: React.ReactNode;
    title: string;
    content: string | ContentfulRichText;
    position?: 'left' | 'right';
}

const TooltipV2 = ({ children, title, content, position = 'left' }: TooltipV2Props) => {
    const
        isMobile = useIsMobile(),
        innerBorderRef = React.useRef(null);

    const
        [ isOpen, setIsOpen ] = React.useState(false),
        [ dialogPosition, setDialogPosition ] = React.useState(0);

    const toggleOpen = (element: any) => {
        element.preventDefault();
        element.stopPropagation();

        setIsOpen(!isOpen);

        const bounding = element.target.getBoundingClientRect();

        setDialogPosition(bounding.top - bounding.height);
    };

    useClickAway(innerBorderRef, () => setIsOpen(false));

    const
        toolTipProps = {
            title,
            content,
            innerBorderRef,
            onClose: toggleOpen,
        },
        tooltipDialogProps = {
            ...toolTipProps,
            top: dialogPosition,
            open: isOpen,
        };

    return (
        <Box
            className={ `tooltip-v2 tooltip-icon position-${ position }` }
            width="fit-content"
            onClick={ toggleOpen }
            sx={ {
                '.tooltip-popover:before,.tooltip-popover:after': {
                    top: '40px!important',
                },
            } }
        >
            { children }

            { !isMobile && isOpen && <TooltipPopover { ...toolTipProps }/> }

            { isMobile && <TooltipDialog { ...tooltipDialogProps }/> }
        </Box>
    );
};

export default TooltipV2;
