import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from '@store';
import { ConfigurationResponse, SimpleConfiguration } from '@models';
import { prepareRetailerName } from '@helpers';

export const configuratorApi = createApi({
    reducerPath: 'configuratorApi',
    tagTypes: [ 'Configurator' ],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.GATSBY_MICROSITE_API_URL,
        prepareHeaders: (headers, { getState }) => {
            const app = (getState() as RootState).app;
            headers.set('Accept-Retailer', prepareRetailerName(app.retailer));
            headers.set('Accept-Language', app.locale);
        }
    }),
    endpoints: build => ({
        createConfiguration: build.mutation<ConfigurationResponse, { configuration: SimpleConfiguration, image: string }>({
            query: ({ configuration, image }) => ({
                url: 'configurator',
                method: 'POST',
                body: { configuration, image }
            }),
        })
    })
});

export const {
    useCreateConfigurationMutation
} = configuratorApi;
