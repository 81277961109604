// Use 'configurator' class to make styles closed
// and to prevent impact on other MUI elements

import { Theme } from '@mui/material';

const ThemeStairsConfigurator = (theme: Theme | any) => {
    const styles = {
        palette: {...theme.palette.configurator},
        stepRadius: 16,
        radius: 12,
        borderWidth: 1,
        height: 56,
        active: {
            borderWidth: 2,
        },
        tile: {
            size: 130,
            sizeMobile: 110,
        },
        card: {
            size: 145,
            sizeMobile: 125,
            avatarSize: 120,
        }
    };

    return {
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        '&.configurator': {
                            // '&.MuiCheckbox-root.Mui-checked': {
                            //     color: theme.palette.configurator.checkbox.primary
                            // },

                            // ToggleMoreButton
                            '&.toggle-more-button': {
                                width: '100%',
                                justifyContent: 'normal',
                                fontWeight: 'bold',
                                padding: '24px 0 8px',
                                fontSize: theme.typography.body2.fontSize,
                                fontFamily: theme.typography.body2.fontFamily,
                                color: styles.palette.toggleMore.text.primary,

                                svg: {
                                    fontSize: theme.typography.body1.fontSize,
                                    fontFamily: theme.typography.body1.fontFamily,
                                    fontWeight: theme.typography.body1.fontWeight,
                                    marginTop: -2,
                                },
                            },

                            // [Display Type]: Button
                            '&.button-display-type': {
                                justifyContent: 'space-between',
                                width: '100%',
                                gap: 10,
                                padding: '10px 16px 7px',
                                fontSize: theme.typography.body1.fontSize,
                                fontFamily: theme.typography.body1.fontFamily,
                                fontWeight: theme.typography.body1.fontWeight,

                                color: styles.palette.options.button.text.main.primary,
                                borderColor: styles.palette.options.button.border.primary,
                                background: styles.palette.options.button.background.primary,

                                height: styles.height,
                                borderRadius: styles.radius,
                                borderWidth: styles.borderWidth,

                                'span + span': {
                                    color: styles.palette.options.button.text.second.primary,
                                },

                                '&.active, &:hover': {
                                    fontWeight: 'normal',
                                    background: styles.palette.options.button.background.active,
                                    borderColor: styles.palette.options.button.border.active,
                                    boxShadow:  `0px 0px 0px 1px ${ styles.palette.options.button.border.active }`,
                                    color: styles.palette.options.button.text.main.active,

                                    'span + span': {
                                        color: styles.palette.options.button.text.second.active,
                                    },
                                },
                            },

                            // [Display Type]: Chip
                            '&.chip-display-type': {
                                justifyContent: 'space-between',
                                height: 32,
                                padding: '4px 16px 3px',
                                borderRadius: 10,

                                fontSize: theme.typography.body2.fontSize,
                                fontFamily: theme.typography.body2.fontFamily,
                                fontWeight: theme.typography.body2.fontWeight,

                                backgroundColor: styles.palette.options.chip.background.primary,
                                borderColor: styles.palette.options.chip.border.primary,
                                color: styles.palette.options.chip.text.primary,

                                '&.active, &:hover': {
                                    fontWeight: 'normal',
                                    backgroundColor: styles.palette.options.chip.background.active,
                                    borderColor: styles.palette.options.chip.border.active,
                                    color: styles.palette.options.chip.text.active
                                },
                            },

                            // [Display Type]: Radio
                            '&.radio-display-type': {
                                justifyContent: 'space-between',
                                width: '100%',
                                gap: 10,
                                padding: '10px 16px 7px',

                                fontSize: theme.typography.body1.fontSize,
                                fontFamily: theme.typography.body1.fontFamily,
                                fontWeight: theme.typography.body1.fontWeight,

                                color: styles.palette.options.radio.text.main.primary,
                                borderColor: styles.palette.options.radio.border.primary,
                                background: styles.palette.options.radio.background.primary,

                                height: styles.height,
                                borderRadius: styles.radius,
                                borderWidth: styles.borderWidth,

                                '&:hover, &.active': {
                                    fontWeight: 'normal',
                                    background: styles.palette.options.radio.background.active,
                                    borderColor: styles.palette.options.radio.border.active,
                                    boxShadow:  `0px 0px 0px 1px ${ styles.palette.options.radio.border.active }`,
                                    color: styles.palette.options.radio.text.main.active,

                                    'div .MuiTypography-root': {
                                        color: styles.palette.options.radio.text.second.active,
                                    },
                                },

                                'div .MuiTypography-root': {
                                    color: styles.palette.options.radio.text.second.primary,
                                },

                                '.MuiRadio-root': {
                                    padding: 0,
                                    color: styles.palette.options.radio.control.active
                                },
                                '.MuiRadio-root.Mui-checked': {
                                    color: styles.palette.options.radio.control.active
                                }
                            },
                        },
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    root: {
                        '&.configurator': {

                            // [Display Type]: Select
                            '&.select-display-type': {
                                borderRadius: `${ styles.radius } !important`,
                                height: styles.height,
                                minWidth: 252,
                                fontSize: theme.typography.body1.fontSize,
                                fontFamily: theme.typography.body1.fontFamily,
                                fontWeight: theme.typography.body1.fontWeight,
                            },
                        },
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        '&.configurator': {

                            // [Step Header]: Title
                            '&.step-header-title': {
                                fontWeight: 'bold',
                                fontSize: theme.typography.configurator.h3.fontSize
                            },

                            // [Option Header]: Title
                            '&.option-header-title': {
                                fontWeight: 'bold',
                                fontSize: theme.typography.configurator.h5.fontSize
                            },

                            // [Option Value Layout]: Title
                            '&.option-value-title': {
                                color: styles.palette.title.text.primary,
                            },

                            '&.option-value-group-title': {
                                paddingBottom: 8,
                            },
                            '&.option-value-group-title-grid': {
                                fontSize: 14
                            },
                        },
                    },
                },
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        '&#pdp-wrapper': {
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 4,
                            [theme.breakpoints.down('sm')]: {
                                flexDirection: 'column',
                            },
                            '.landscape-gallery': {
                                [theme.breakpoints.down('md')]: {
                                    marginBottom: 32,
                                },
                                '.image-gallery': {
                                    '.gallery-nav-btn': {
                                        position: 'absolute',
                                        top: '50%',
                                        zIndex: 4,
                                        transition: 'all 0.3s',
                                        background: styles.palette.gallery.controls.background.primary,
                                        svg: {
                                            color: styles.palette.gallery.controls.svg.primary,
                                        },
                                        '&.left': {
                                            left: 16,
                                        },
                                        '&.right': {
                                            right: 16,
                                        },
                                        '&:hover': {
                                            transform: 'scale(1.1)',
                                        },
                                    },
                                    '.image-gallery-bullets-container': {
                                        background: styles.palette.gallery.bullets.background.primary,
                                        position: 'absolute',
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        margin: '0 auto',
                                        borderRadius: 50,
                                        padding: '8px 12px',
                                        width: 'fit-content',
                                        height: 25,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        '.image-gallery-bullet': {
                                            '&:not(:first-of-type)': {
                                                marginLeft: 8,
                                            },
                                            width: 8,
                                            height: 8,
                                            margin: 0,
                                            padding: 0,
                                            opacity: 0.3,
                                            backgroundColor: styles.palette.gallery.imageBullet.background.primary,
                                            borderColor: styles.palette.gallery.imageBullet.border.primary,
                                            '&.active': {
                                                opacity: 1,
                                            },
                                        },
                                    },
                                },
                                '.gallery-3d-view-button': {
                                    position: 'absolute',
                                    bottom: 16,
                                    right: 16,
                                    zIndex: 4,
                                    background: styles.palette.gallery.viewButton.background.primary,
                                },
                                '.image-gallery-swipe': {
                                    minHeight: 300,
                                    [theme.breakpoints.down('md')]: {
                                        minHeight: 150,
                                    },
                                },
                                '.retailer-logo': {
                                    '&.pdp': {
                                        [theme.breakpoints.down('md')]: {
                                            maxWidth: 135,
                                        },
                                    },
                                },
                            },
                            '.product-title': {
                                [theme.breakpoints.up('md')]: {
                                    paddingLeft: 8 * 3,
                                },
                            },
                            '.pdp-gallery': {
                                [theme.breakpoints.up('md')]: {
                                    maxWidth: 'calc(100% - 380px)',
                                },
                                [theme.breakpoints.up('lg')]: {
                                    width: 'calc(100% - 520px)',
                                },
                            },
                            '.pdp-configurator': {
                                padding: 0,
                                width: '100%',
                                [theme.breakpoints.up('md')]: {
                                    maxWidth: 380,
                                },
                                [theme.breakpoints.up('lg')]: {
                                    maxWidth: 520,
                                },
                            },
                            '.pdp-content': {
                                width: '100%',
                            },
                            '.combined-price': {
                                flexDirection: 'row-reverse',
                                alignItems: 'start',
                                justifyContent: 'left',
                                gap: 1,
                                '.price-text': {
                                    marginTop: 2,
                                    s: {
                                        letterSpacing: -1,
                                    },
                                },
                            },
                            '.pdp-discount-accordion, .pdp-discount-label': {
                                margin: '28px 0',
                            },
                            '.configurator': {
                                // [Option Value Group Layout] Grid
                                '&.option-value-group-grid': {
                                    marginLeft: -16,
                                },

                                // [Option Value Layout] Grid
                                '&.option-value-grid': {
                                    marginLeft: -16,
                                },

                                '.option-value-grid-layout': {
                                    marginLeft: -16,
                                },
                            },
                            '.pdp-product-content': {
                                '.title-collapse': {
                                    '.MuiButton-root[aria-label="title-collapse"]': {
                                        height: 96,
                                    },
                                    hr: {
                                        borderColor: styles.palette.hr.border.primary,
                                    },
                                },
                            },
                            '.delivery-info': {
                                marginTop: 0,
                            },
                            '.delivery-info, .service-info': {
                                'p, a': {
                                    fontSize: 'inherit',
                                },
                            },
                        },
                        '.MuiGrid-item': {
                            'a': {
                                textDecoration: 'underline'
                            },
                        }
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        '&.configurator': {
                            'h3': {
                                fontSize: theme.typography.configurator.h3.fontSize
                            },

                            // Checkbox in footer
                            '.MuiCheckbox-root': {
                                color: styles.palette.checkbox.primary,

                                '&.Mui-checked': {
                                    color: styles.palette.checkbox.active
                                },
                            },

                            // [Step Layout] Step Block
                            '.step-block': {
                                backgroundColor: styles.palette.step.background.primary,
                                borderRadius: styles.stepRadius,
                            },

                            // [Step Header] && [Option Header]: Tooltip
                            '&.step-header, &.option-header': {
                                '.tooltip-icon .MuiIconButton-root': {
                                    padding: 0,
                                    margin: 0,
                                },

                                '.tooltip-icon .MuiIconButton-root, .tooltip-icon .MuiIconButton-root .MuiSvgIcon-root path': {
                                    color: styles.palette.tooltip.text.primary,
                                },

                                '.more-info': {
                                    fontSize: 11,
                                    textDecoration: 'underline',
                                    textWrap: 'nowrap',
                                    marginLeft: 0,
                                    color: styles.palette.tooltip.text.primary,
                                },
                                '.tooltip-icon': {
                                    '.tooltip-popover:after, .tooltip-popover:before': {
                                        top: 40,
                                    },

                                    '.tooltip-popover': {
                                        width: 350,
                                    },

                                    '.tooltip-popover-title': {
                                        fontSize: '1rem',
                                    },

                                    '.tooltip-popover-content': {
                                        fontSize: '0.95rem',
                                    },
                                },
                            },

                            // [Display Type]: Card
                            '&.card-display-type': {
                                overflow: 'hidden',
                                paddingBottom: 16,
                                cursor: 'pointer',
                                borderStyle: 'solid',
                                borderRadius: 20,
                                height: '100%',

                                borderWidth: styles.borderWidth,
                                width: styles.card.size,

                                borderColor: styles.palette.options.card.border.primary,
                                backgroundColor: styles.palette.options.card.background.primary,

                                [theme.breakpoints.down('md')]: {
                                    width: styles.card.sizeMobile,
                                },

                                '&.active, &:hover': {
                                    borderColor: styles.palette.options.card.border.active,
                                    boxShadow:  `0px 0px 0px 1px ${ styles.palette.options.card.border.active }`,
                                    backgroundColor: styles.palette.options.card.background.active,

                                    '.card-title': {
                                        color: styles.palette.options.card.text.main.active,
                                    },

                                    '.card-subtitle': {
                                        color: styles.palette.options.card.text.second.active,
                                    }
                                },

                                '.MuiAvatar-root': {
                                    width: `100%`,
                                    height: styles.card.avatarSize,
                                },

                                '.card-title': {
                                    fontWeight: 400,
                                    lineHeight: 1.2,
                                    textAlign: 'center',
                                    padding: '0 5px',
                                    color: styles.palette.options.card.text.main.primary
                                },

                                '.card-subtitle': {
                                    color: styles.palette.options.card.text.second.primary,
                                    fontWeight: 400,
                                    textAlign: 'center',
                                },
                            },

                            // [Display Type]: Check
                            '&.check-display-type': {
                                padding: '12px 16px',
                                borderStyle: 'solid',
                                cursor: 'pointer',

                                borderRadius: styles.radius,
                                borderWidth: styles.borderWidth,

                                borderColor: styles.palette.options.check.border.primary,
                                backgroundColor: styles.palette.options.check.background.primary,

                                '&.active': {
                                    borderColor: styles.palette.options.check.border.active,
                                    boxShadow:  `0px 0px 0px 1px ${ styles.palette.options.check.border.active }`,
                                    backgroundColor: styles.palette.options.check.background.primary,

                                    '.check-description': {
                                        color: styles.palette.options.check.text.active,
                                    },
                                },

                                'img': {
                                    maxWidth: 70,
                                },

                                '.MuiCheckbox-root': {
                                    padding: 0,
                                },

                                '.check-description': {
                                    color: styles.palette.options.check.text.primary,
                                },
                            },

                            // [Display Type]: Tile
                            '&.tile-display-type': {
                                cursor: 'pointer',
                                width: styles.tile.size,

                                [theme.breakpoints.down('md')]: {
                                    width: styles.tile.sizeMobile,
                                },
                                '&.active, &:hover': {
                                    '.tile-block': {
                                        borderColor: styles.palette.options.tile.border.active,
                                        boxShadow:  `0px 0px 0px 1px ${ styles.palette.options.tile.border.active }`,
                                        backgroundColor: styles.palette.options.tile.background.active,

                                        '.tile-active-mark': {
                                            '& > div': {
                                                backgroundColor: styles.palette.options.tile.mark.background.active,
                                            },
                                            'svg': {
                                                color: styles.palette.options.tile.mark.svg.active,
                                            },
                                        },

                                        '.tile-label': {
                                            background: styles.palette.options.tile.label.background.active,
                                            color: styles.palette.options.tile.label.text.active,
                                        },
                                    },
                                },

                                '.tile-block': {
                                    borderRadius: 20,
                                    borderStyle: 'solid',
                                    cursor: 'pointer',
                                    overflow: 'hidden',
                                    position: 'relative',

                                    width: styles.tile.size,
                                    height: styles.tile.size,
                                    borderWidth: styles.borderWidth,

                                    borderColor: styles.palette.options.tile.border.primary,
                                    backgroundColor: styles.palette.options.tile.background.primary,

                                    [theme.breakpoints.down('md')]: {
                                        width: styles.tile.sizeMobile,
                                        height: styles.tile.sizeMobile,
                                    },
                                },

                                '.tile-active-mark': {
                                    '& > div': {
                                        width: 43,
                                        height: 20,
                                        transform: 'rotate(45deg) translate(6px, -12px)',
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,

                                        backgroundColor: styles.palette.options.tile.mark.background.primary,
                                    },
                                    'svg': {
                                        position: 'absolute',
                                        top: 3,
                                        right: 3,
                                        fontSize: theme.typography.caption.fontSize,
                                        fontFamily: theme.typography.caption.fontFamily,
                                        fontWeight: theme.typography.caption.fontWeight,

                                        color: styles.palette.options.tile.mark.svg.primary,
                                    },
                                },

                                '.tile-label': {
                                    padding: '4px 10px 2px',
                                    borderRadius: '4px 0 0 0',
                                    lineHeight: '1',
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,

                                    fontSize: theme.typography.caption.fontSize,
                                    fontFamily: theme.typography.caption.fontFamily,
                                    fontWeight: theme.typography.caption.fontWeight,

                                    background: styles.palette.options.tile.label.background.primary,
                                    color: styles.palette.options.tile.label.text.primary,
                                },

                                '.tile-title': {
                                    textAlign: 'center',
                                    marginTop: 16,
                                    color: styles.palette.options.tile.text.main.primary,
                                },

                                '.MuiAvatar-root': {
                                    width: '100%',
                                    height: '100%',
                                },
                            },

                            // [Display Type]: Slider
                            '&.slider-display-type': {
                                height: 100,
                                marginBottom: 20,

                                '& > .MuiStack-root': {
                                    width: '100%',
                                },

                                // styles for Slider
                                '.MuiSlider-root': {
                                    width: '80%',
                                    margin: '0 auto',
                                },

                                '.MuiSlider-rail': {
                                    height: 16,
                                    opacity: '1',
                                    color: styles.palette.options.slider.rail.background.primary
                                },

                                '.MuiSlider-track': {
                                    borderColor: 'transparent',
                                    background: 'none',
                                },

                                '.MuiSlider-mark': {
                                    width: 4,
                                    height: 4,
                                    background: styles.palette.options.slider.mark.background.primary,
                                    borderRadius: '50%',
                                },

                                '.MuiSlider-markLabel': {
                                    marginTop: 10,
                                    color: styles.palette.options.slider.text.primary,

                                    '&.MuiSlider-markLabelActive': {
                                        color: styles.palette.options.slider.text.active,
                                    }
                                },

                                '.MuiSlider-thumb': {
                                    width: 2,
                                    height: 44,
                                    borderRadius: 0,
                                    marginLeft: 1,
                                    boxShadow: 'none !important',
                                    backgroundColor: styles.palette.options.slider.thumb.background.primary,

                                    '&:hover, &.Mui-focusVisible': {
                                        boxShadow: 'none',
                                    },
                                },

                                '.MuiSlider-valueLabelOpen': {
                                    width: 48,
                                    height: 44,
                                    background: styles.palette.options.slider.valueLabel.background.primary,
                                    borderRadius: '50%',
                                    top: -4,

                                    '&:before': {
                                        display: 'none',
                                    },
                                },

                                '.MuiSlider-valueLabelLabel': {
                                    fontFamily: theme.typography.body1.fontFamily,
                                    fontWeight: 400,
                                    color: styles.palette.options.slider.valueLabel.text.primary
                                },

                                '.MuiIconButton-root': {
                                    color: styles.palette.options.slider.button.svg.primary
                                }
                            },

                            // [Summary]
                            '&.price-rows': {
                                '.price-symbol': {
                                    top: 0,
                                    fontSize: '1.115rem',
                                },
                                '.MuiTypography-h5': {
                                    lineHeight: 1.4,

                                    'sup': {
                                        verticalAlign: 'text-top'
                                    }
                                }
                            },
                            '&.total-price': {
                                '.combined-price': {
                                    justifyContent: 'right',
                                },
                            },

                            '.cta-btn': {
                                width: '100%',
                                pointerEvents: 'auto',

                                '&:not(.Mui-disabled)': {
                                    background: styles.palette.cta.main.background.primary,
                                    color: styles.palette.cta.main.text.primary,
                                },

                                '&.Mui-disabled': {
                                    background: styles.palette.cta.disabled.background.primary,
                                    color: styles.palette.cta.disabled.text.primary
                                },
                            },

                            '.cta-text': {
                                fontWeight: 700,
                            },
                        },
                    },
                },
            },
        },
    };
};


export default ThemeStairsConfigurator;

