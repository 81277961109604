import React, { createContext } from 'react';
import { ContentfulRichText, type SettingsFor3dView, type Configurator } from '@models';

export interface Viewer3DHTMLElement extends HTMLElement {
    getGroups: () => {
        name: string;
        objects: string[];
    }[];
    removeTexture: (config: Record<string, any>) => void;
    setTexture: (config: Record<string, any>) => Promise<void>;
    showObject: (config: Record<string, any>) => void;
    hideObject: (config: Record<string, any>) => void;
    getImage: (config: Record<string, any>) => string;
    requestRender: () => void;
    update3dViewSettings: (settings: SettingsFor3dView) => void;
    generateImage: () => string;
}

type Object = Record<string, any>;

interface PDPContextProps {
    locationState: Object;
    retailerSettings: Record<string, any> & { discountDescription?: ContentfulRichText };

    product: Object;
    updateProductContext: (key: string, value: string) => void;

    price: number;
    setPrice: React.Dispatch<number>;

    viewer3dRef: React.RefObject<Viewer3DHTMLElement | null>;
    isViewer3dLoaded: boolean;
    setIsViewer3dLoaded: React.Dispatch<boolean>;

    sharedData: Object;
    setSharedData: React.Dispatch<Object>;

    isError: boolean;
    setIsError: React.Dispatch<boolean>;

    configurator: Configurator;
    setConfigurator: React.Dispatch<Configurator>;
}

export const defaultContext: PDPContextProps = {
    locationState: {},
    retailerSettings: {},

    product: {},
    updateProductContext: async (key, value) => {},

    price: 0,
    setPrice: value => {},

    viewer3dRef: { current: null },
    isViewer3dLoaded: false,
    setIsViewer3dLoaded: value => {},

    sharedData: {},
    setSharedData: value => {},

    isError: false,
    setIsError: value => {},

    configurator: {
        node_locale: '',
        name: '',
        type: 'stairs',
        steps: [],
        retailers: [],
        usp: [],
        terms: {
            name: '',
            linkLabel: '',
            termsTitle: '',
            label: '',
            content: { raw: '' },
        },
    },
    setConfigurator: value => {},
};
const PDPContext = createContext(defaultContext);

export const PDPProvider = ({ data, children }) => {
    data.viewer3dRef = React.useRef<Viewer3DHTMLElement>(null);

    return (
        <PDPContext.Provider value={ data }>
            { children }
        </PDPContext.Provider>
    );
};

export const usePDPContext = () => {
    const context = React.useContext(PDPContext);

    if (context === undefined)
        throw new Error('usePDPContext must be inside a Product');

    return context;
};
