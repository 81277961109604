import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import type { AllContentfulConfigurator, Configuration, ConfiguratorOptionValue, Price, PriceType, SimpleConfiguration } from '@models';
import { ConfiguratorContext } from '@context/ConfiguratorContext';
import { useRetailer, useMicrocopy, useLocale } from '@hooks/common';
import { getPriceValue, prepareRetailerName, prepareUrlLocale } from '@helpers';

export const useConfigurator = (type: string, retailer = useRetailer(), locale: string = useLocale()) => {
    const
        { configurator } = useStaticQuery<AllContentfulConfigurator>(graphql`
            query {
                configurator: allContentfulConfigurator {
                    ...configurator
                }
            }
        `);

    return configurator.nodes.find(item =>
        item.type === type
        && item.node_locale === prepareUrlLocale(locale)
        && item.retailers.find(item => prepareRetailerName(item.name) === prepareRetailerName(retailer)),
    );
};

export const useConfiguratorContext = () => {
    const context = React.useContext(ConfiguratorContext);

    if (context === undefined)
        throw new Error('useConfiguratorContext must be inside a ConfiguratorContext');

    return context;
};

export const useConfiguratorPriceValue = (value: ConfiguratorOptionValue, price?: Price): number => {
    const { configuration } = useConfiguratorContext();

    return getPriceValue(value, configuration, price);
}

export const useConfiguratorFormatPrice = (value: number, type: PriceType = 'base', placeholder = true) => {
    if (value <= 0 && placeholder)
        return useMicrocopy().get('configurator.price.included', 'Included');

    return type === 'additional'
        ? `+€${ parseFloat((value / 100).toString()).toFixed(0) }`
        : `€ ${ parseFloat((value / 100).toString()).toFixed(0) }`;
};

export const useSimpleConfigurator = (configuration: Configuration): SimpleConfiguration => {
    const data: SimpleConfiguration = {};

    for (const key in configuration)
        data[key] = {
            code: configuration[key].code,
            group: configuration[key].group?.code || null,
        };

    return data;
};
