import type {
    Configuration,
    Configurator,
    ConfiguratorOption,
    ConfiguratorOptionValue,
    Price,
    SettingsFor3dView,
} from '@models';

export const MATERIAL_OPTION_CODE = 'material';

export const filterByConditions = <T extends ConfiguratorOption | ConfiguratorOptionValue>(items: T[], configuration: Configuration): T[] =>
    items.filter(item => {
        if (!item.conditions)
            return true;

        for (const condition of item.conditions)
            if (!configuration[condition.code] || !condition.values.some(value => value.code === configuration[condition.code]?.code))
                return false;

        return true;
    });

export const calculateActiveConfigurator = (configurator: Configurator, configuration: Configuration): Configurator => ({
    ...configurator,
    steps: configurator.steps.map(step => ({
        ...step,
        options: filterByConditions(step.options, configuration).map(option => ({
            ...option,
            values: filterByConditions(option.values, configuration).map(value => ({ ...value })),
        })),
    })),
});

const getInitialValue = (option: ConfiguratorOption, material?: {
    code: string,
    value: string
}): ConfiguratorOptionValue => {
    let value: ConfiguratorOptionValue | undefined;

    if (material && option.code === material.code)
        value = option.values.find(value => value.code === material.value);

    return value || option.defaultValue || option.values[0];
};

export const makeDefaultConfiguration = (configurator: Configurator, material: {
    code: string,
    value: string
}): Configuration => {
    const result = {};

    for (const step of configurator.steps)
        for (const option of step.options)
            result[option.code] = getInitialValue(option, material);

    return result;
};

const validatedValue = (option: ConfiguratorOption, value?: ConfiguratorOptionValue) =>
    option.values.find(item => item.code === value?.code);

export const syncConfiguration = (configuration: Configuration, configurator: Configurator): Configuration => {
    const result: Configuration = {};

    for (const step of configurator.steps)
        for (const option of step.options)
            result[option.code] = validatedValue(option, configuration[option.code]) || getInitialValue(option);

    return result;
};

export const calculatePrice = (configuration: Configuration, configurator: Configurator) => {
    let total = 0;

    for (const step of configurator.steps)
        for (const option of step.options)
            total += getPriceValue(configuration[option.code], configuration, option.price);

    return total;
};

export const getPriceValue = (optionValue: ConfiguratorOptionValue, configuration: Configuration, price?: Price) => {
    if (!price) return 0;

    const priceValue = price.values.find(item =>
        item.type === 'configuratorOptionValue'
            ? item.code === optionValue?.code
            : item.code === optionValue.group?.code,
    );

    if (!priceValue) return 0;

    const priceMatrix = priceValue.matrix.find(item => item.keys.every((key, index) =>
        key.type === 'configuratorOptionValue'
            ? key.code === configuration[price.depends[index].code]?.code
            : key.code === configuration[price.depends[index].code]?.group?.code,
    ));

    if (!priceMatrix) return 0;

    return priceMatrix.value;
};

export const getSettingsFor3dView = (configurator: Configurator, configuration: Configuration) => {
    const settings: SettingsFor3dView = {};

    configurator.steps.forEach(step =>
        step.options.forEach(option => {
            if (!configuration[option.code]) return;

            const value = option.values.find(value => value.code === configuration[option.code].code);

            if (value && value.settingsFor3dView)
                Object.entries(value.settingsFor3dView)
                    .filter(([ , value ]) => value !== null)
                    .forEach(([ key, value ]) => settings[key] = value);
        }),
    );

    return settings;
};

export const getConfiguratorStepNameByValue = (configurator: Configurator, value: ConfiguratorOptionValue) => {
    for (const step of configurator.steps)
        for (const option of step.options)
            if (option.values.find(item => item.code === value.code))
                return option.code;

    return;
};
