import { alpha, createTheme } from '@mui/material';
import BaseTheme, { createFontFamily } from './base/base';
import ThemeComponents from './base/components';
import Ubuntu from '@fonts/Ubuntu-Regular.woff2';
import UbuntuBold from '@fonts/Ubuntu-Bold.woff2';

import RubrikNewLight from '@fonts/RubrikNew-Light.woff2';
import RubrikNewBold from '@fonts/RubrikNew-Bold.woff2';

let palette = {
    primary: {
        main: '#e20019',
        light: '#F9F9F8',
        lightTwo: '#fce7e8',
    },
    grey: {
        100: '#fff',
        300: '#71767a'
    },
    white: {
        main: '#fff'
    },
    green: {
        main: '#4CAF50',
        secondary: '#66DC80'
    },
    black: {
        main: '#000'
    },
    divider: '#c4c4c4',
    tonalOffset: 0.1,
    checklist: {
        main: '#B7B3A2',
        light: '#F9D839'
    },
    header: {
        main: '#696f6a'
    },
    discount: {
        text: '#F9D839',
        label: '#D6232E'
    },
    wizard: {
        dark: '#e20019'
    }
}

let configurator = {
    toggleMore: {
        text: {
            primary: palette.primary.main
        }
    },
    tooltip: {
        text: {
            primary: palette.primary.main
        }
    },
    checkbox: {
        active: palette.primary.main
    },
    cta: {
        main: {
            background: {
                primary: palette.primary.main
            },
            text: {
                primary: palette.white.main
            },
        }
    },
    options: {
        button: {
            border: {
                active: palette.primary.main
            }
        },
        chip: {
            background: {
                active: palette.primary.lightTwo
            },
            border: {
                active: palette.primary.lightTwo
            },
            text: {
                active: '#000'
            }
        },
        radio: {
            border: {
                active: palette.primary.main
            },
            control: {
                active: palette.primary.main
            },
        },
        card: {
            border: {
                active: palette.primary.main
            },
            text: {
                second: {
                    primary: '#7b7c7a',
                    active: '#7b7c7a'
                }
            }
        },
        check: {
            border: {
                active: palette.primary.main
            }
        },
        tile: {
            border: {
                active: palette.primary.main
            },
            mark: {
                background: {
                    primary: palette.primary.main,
                    active: palette.primary.main
                }
            },
            label: {
                background: {
                    primary: palette.primary.lightTwo,
                    active: palette.primary.lightTwo
                },
                text: {
                    primary: '#000',
                    active: '#000'
                }
            }
        },
        slider: {
            rail: {
                background: {
                    primary: palette.primary.lightTwo
                }
            },
            mark: {
                background: {
                    primary: palette.primary.main
                }
            },
            thumb: {
                background: {
                    primary: palette.primary.main
                }
            },
            valueLabel: {
                background: {
                    primary: palette.primary.main
                },
            }
        },
    }
}

let paletteTheme =  {
    ...palette,
    configurator: {
        ...configurator
    }
}

let theme: any = createTheme({
    palette: {
        ...paletteTheme
    },
    typography: {
        ...createFontFamily('RubrikNew, Ubuntu, sans-serif', {
            fontSize: 14,
            configurator: {
                h3: {
                    fontSize: '1.375rem'
                }
            }
        }),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 423,
            md: 680,
            lg: 991,
            xl: 1336,
        },
    }
});

theme = createTheme(theme, {
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'RubrikNew';
                  src: url('${ RubrikNewLight }') format('woff2');
                }
                @font-face {
                  font-family: 'RubrikNewBold';
                  src: url('${ RubrikNewBold }') format('woff2');
                  font-weight: bold;
                }
                @font-face {
                  font-family: 'Ubuntu';
                  src: url('${ Ubuntu }') format('woff2');
                }
                @font-face {
                  font-family: 'UbuntuBold';
                  src: url('${ UbuntuBold }') format('woff2');
                  font-weight: bold;
                }
                body { -webkit-overflow-scrolling:touch }
                ::-webkit-scrollbar { 
                    WebkitAppearance: none; 
                    width: 10px; 
                    height: 10px; 
                }
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 5px;
                    border: 2px solid #eeeeee;
                }
                ::-webkit-scrollbar-track {
                    background-color: #eeeeee;
                    border-radius: 10px;
                }
            `
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    '.MuiButtonBase-root.MuiChip-root': {
                        '&:hover, &.MuiChip-filled': {
                            border: '1px solid #413F33',
                            backgroundColor: `${ alpha('#0C193A', 0.1) } !important`
                        }
                    },
                    '&.filter-block': {
                        '.MuiButtonBase-root[aria-label="title-collapse"]': {
                            fontSize: '0.88rem',
                            lineHeight: '1.13rem',
                            letterSpacing: 0,
                            fontWeight: 800,
                            height: 'unset',
                            borderRadius: 'unset!important'
                        },
                        '.MuiCheckbox-root.checkbox-field': {
                            color: '#b7b3a2'
                        }
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.header-title': {
                        fontWeight: 800,
                        fontSize: '3.75rem',
                        lineHeight: '6rem',
                        letterSpacing: -1.5,
                        textAlign: 'center',
                        [theme.breakpoints.down('md')]: {
                            lineHeight: '4rem'
                        }
                    },
                    '&.header-description': {
                        fontFamily: 'Ubuntu',
                        fontSize: '1rem',
                        lineHeight: '1.5rem',
                        letterSpacing: 0.5,
                        fontWeight: 400,
                        textAlign: 'center'
                    },
                    '&.promo-label': {
                        background: theme.palette.discount.label,
                        color: theme.palette.discount.text,
                        fontSize: 14,
                        bottom: 10,
                        borderRadius: 4,
                        padding: '4px 8px',
                        lineHeight: '18px'
                    },
                    '&.popup-title': {
                        marginBottom: '20px'
                    },
                    '&.pdp-discount-label': {
                        '.promo-label': {
                            marginTop: 4
                        }
                    }
                }
            }
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    '&.usp-object': {
                        '.MuiSvgIcon-root': {
                            width: '1.3em',
                            height: '1.3em',
                            color: theme.palette.green.main
                        },
                        '.MuiTypography-root': {
                            fontFamily: 'Ubuntu'
                        },
                        '.check-icon': {
                            flexShrink: 0
                        }
                    },
                    '.tooltip-icon': {
                        '.MuiIconButton-root': {
                            color: '#8D8977'
                        }
                    },
                    '.price-slider': {
                        width: '90%',
                        margin: '0 auto',
                        marginTop: '24px',
                        '.MuiSlider-root': {
                            '.MuiSlider-thumb': {
                                background: theme.palette.primary.main,
                                border: `2px solid ${ theme.palette.primary.main }`,
                                width: '24px',
                                height: '24px'
                            }
                        },
                        '.MuiSlider-track': {
                            color: theme.palette.primary.main
                        },
                        '.MuiSlider-rail,.MuiSlider-mark': {
                            backgroundColor: theme.palette.checklist.main,
                            opacity: 0.38
                        },
                        '.MuiSlider-markLabel': {
                            '&[data-index="0"]': {
                                transform: 'translateX(-24px)'
                            },
                            '&[data-index="1"]': {
                                transform: 'translateX(calc(-100% + 24px))'
                            },
                            '.price': {
                                '.MuiTypography-root': {
                                    fontSize: 14,
                                },
                                '.MuiBox-root': {
                                    top: 0
                                }
                            }
                        }
                    },
                    '&[aria-label="pdp-go-back"]': {
                        color: '#71767A'
                    },
                    '&.confirm-dialog': {
                        width: '100%',
                        padding: '16px 12px',
                        '.MuiButtonBase-root': {
                            background: 'none',
                            height: '48px',
                            fontSize: 14,
                            color: '#505971',
                            '&:first-of-type': {
                                background: theme.palette.black.main,
                                color: theme.palette.white.main
                            }
                        }
                    },
                    '&.header-content': {
                        maxWidth: 1000,
                        paddingTop: 0
                    },
                    '&.header-top': {
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: theme.palette.header.main,
                        // height: 160,
                        height: 107,
                        '&:after': {
                            backgroundColor: theme.palette.white.main,
                            borderTopLeftRadius: 35,
                            borderTopRightRadius: 35,
                            content: '""',
                            height: 35,
                            bottom: -1,
                            width: '100%',
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            [theme.breakpoints.down('lg')]: {
                                borderTopLeftRadius: 32,
                                borderTopRightRadius: 32,
                            }
                        },
                        '.logo': {
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            flexBasis: '25%',
                            justifyContent: 'center',
                            width: 220,
                            marginTop: 18,
                            a: {
                                display: 'flex',
                                flexDirection: 'row',
                            },
                            img: {
                                height: 30,
                                marginRight: 8,
                                width: 'auto',
                            },
                            [theme.breakpoints.down('lg')]: {
                                paddingLeft: 24
                            }
                        }
                    },
                    '.combined-price': {
                        s: {
                            fontWeight: 800,
                            color: '#7b7c7a',
                            fontSize: '1rem',
                            lineHeight: '1.38rem',
                            letterSpacing: 0,
                        },
                        '.price': {
                            '>.MuiTypography-h5': {
                                fontSize: '1.5rem',
                                lineHeight: '2rem',
                                letterSpacing: 0,
                                fontWeight: 300,
                            }
                        },
                        '.with-discount': {
                            '.price': {
                                '>.MuiTypography-h5': {
                                    color: theme.palette.primary.main,
                                    fontWeight: 800
                                }
                            }
                        }
                    },
                    '&.header-filters': {
                        width: '100%'
                    }
                }
            }
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    '.MuiPagination-ul': {
                        'li:first-of-type, li:last-of-type': {
                            '.MuiButtonBase-root': {
                                color: theme.palette.black.main
                            }
                        },
                        'li': {
                            '.MuiPaginationItem-root': {
                                border: 0,
                                color: theme.palette.primary.main,
                                '&:hover, &.Mui-selected': {
                                    '&:not(.MuiPaginationItem-ellipsis)': {
                                        backgroundColor: '#EEEDE9',
                                        border: 0,
                                        color: theme.palette.black.main
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        MuiCollapse: {
            styleOverrides: {
                root: {
                    '.active-filters': {
                        '.MuiButtonBase-root': {
                            '&[aria-label="close"]': {
                                color: '#3C3C3C'
                            }
                        }
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    height: 48,
                    padding: '0 12px!important',
                    borderRadius: '8px!important',
                    lineHeight: '18px',
                    fontWeight: 'bold',
                    letterSpacing: 0.75,
                    '&.remove-sawlist-item': {
                        height: 'auto'
                    },
                    '&[aria-label="title-collapse"]': {
                        borderRadius: 'initial!important'
                    }
                }
            }
        },
        MuiDiv: {
            styleOverrides: {
                root: {
                    "&#microsite": {
                        padding: 0
                    }
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    '&.header-content-wrapper': {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    },
                    '&#header': {
                        maxWidth: '100% !important',
                        padding: 0
                    },
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    '&.pdp-discount-accordion': {
                        '.MuiAccordionSummary-root': {
                            '.MuiAccordionSummary-expandIconWrapper svg[data-testid="ExpandMoreIcon"]': {
                                fill: `${ theme.palette.discount.text }`
                            },
                        },
                    }
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    '&.product-card': {
                        borderRadius: 8,
                        '.product-subtitle': {
                            fontSize: '0.88rem',
                            lineHeight: '0.88rem',
                            fontWeight: 600,
                            letterSpacing: 2,
                            marginBottom: 8
                        },
                        '.product-title': {
                            fontSize: '1.5rem',
                            lineHeight: '2rem',
                            fontWeight: 800,
                            letterSpacing: 0
                        }
                    }
                }
            }
        }
    }
});

theme = createTheme(BaseTheme, theme);

export default createTheme(theme, ThemeComponents(theme));
