import { createTheme } from '@mui/material';
import BaseTheme, { createFontFamily } from './base/base';
import ThemeComponents from './base/components';
import Rubik from '@fonts/Rubik.woff2';

const palette = {
    primary: {
        main: '#000',
        light: '#f7f7f7',
        lightTwo: '#fef8df',
        dark: '#000'
    },
    secondary: {
        main: '#a7adb2',
    },
    yellow: '#ffe928'
};

let configurator = {
    toggleMore: {
        text: {
            primary: palette.primary.main
        }
    },
    tooltip: {
        text: {
            primary: palette.primary.main
        }
    },
    checkbox: {
        active: palette.primary.main
    },
    cta: {
        main: {
            background: {
                primary: palette.primary.main
            },
            text: {
                primary: '#fff'
            },
        }
    },
    options: {
        button: {
            border: {
                active: palette.primary.main
            }
        },
        chip: {
            background: {
                active: palette.primary.lightTwo
            },
            border: {
                active: palette.primary.lightTwo
            },
            text: {
                active: '#000'
            }
        },
        radio: {
            border: {
                active: palette.primary.main
            },
            control: {
                active: palette.primary.main
            },
        },
        card: {
            border: {
                active: palette.primary.main
            }
        },
        check: {
            border: {
                active: palette.primary.main
            }
        },
        tile: {
            border: {
                active: palette.primary.main
            },
            mark: {
                background: {
                    primary: palette.primary.main,
                    active: palette.primary.main
                }
            },
            label: {
                background: {
                    primary: palette.primary.lightTwo,
                    active: palette.primary.lightTwo
                },
                text: {
                    primary: '#000',
                    active: '#000'
                }
            }
        },
        slider: {
            rail: {
                background: {
                    primary: palette.primary.lightTwo
                }
            },
            mark: {
                background: {
                    primary: palette.primary.main
                }
            },
            thumb: {
                background: {
                    primary: palette.primary.main
                }
            },
            valueLabel: {
                background: {
                    primary: palette.primary.main
                },
            }
        },
    }
}

let paletteTheme =  {
    ...palette,
    configurator: {
        ...configurator
    }
}

let entrepotTheme = {
    palette: {
        ...paletteTheme
    },
    typography: {
        ...createFontFamily(
            'Rubik',
            {
                fontSize: 16,
                h4: {
                    fontSize: '2rem',
                },
                h5: {
                    fontSize: '1.5rem',
                },
            },
        ),
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'Rubik';
                  src: url(${ Rubik }) format('woff2');
                }
                body { -webkit-overflow-scrolling:touch }
                ::-webkit-scrollbar { 
                    WebkitAppearance: none; 
                    width: 10px; 
                    height: 10px; 
                }
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 5px;
                    border: 2px solid #eeeeee;
                }
                ::-webkit-scrollbar-track {
                    background-color: #eeeeee;
                    border-radius: 10px;
                }
            `,
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.MuiButton-contained': {
                        color: palette.yellow,
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.promo-label': {
                        color: palette.yellow,
                    },
                },
            },
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    '.MuiPagination-ul': {
                        'li:first-of-type, li:last-of-type': {
                            '.MuiButtonBase-root': {
                                color: palette.primary.main,
                            },
                        },
                        'li': {
                            '.MuiPaginationItem-root': {
                                fontSize: '1rem',
                                border: 0,
                                color: palette.primary.main,
                                '&:hover, &.Mui-selected': {
                                    '&:not(.MuiPaginationItem-ellipsis)': {
                                        backgroundColor: 'transparent!important',
                                        border: 0,
                                        color: palette.primary.main,
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};

let theme: any = createTheme(BaseTheme, entrepotTheme);

export default createTheme(theme, ThemeComponents(theme));
