import React from 'react';
import { useRetailerLike } from '@hooks/common';
import { retailers } from '@helpers';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { ReactComponent as CheckIcon } from '@images/retailer/Praxis/CheckIcon.svg';

const CheckCircleIcon = (props: any) => {
    if (useRetailerLike([ retailers.Praxis.name, retailers.Brico.name ]))
        return <CheckIcon className={ [ 'check-icon', props.className ?? '' ].join(' ') }/>;

    return <CheckCircleRoundedIcon color="primary" { ...props }/>;
};

export default CheckCircleIcon;
