import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareRetailerName, SawListHelper } from '@helpers';
import { RootState } from '@store';
import { ISawList, ISawListItem } from '@models';
import { setIsSawListLoading, setSawList } from '@store/reducers/sawListSlice';

const onQueryStarted = async (
    id,
    { dispatch, queryFulfilled },
    callback = (data: any) => {},
    onError = (error: any) => {}
) => {
    dispatch(setIsSawListLoading(true));

    return queryFulfilled
        .then((response: any) => callback(response))
        .catch(({ error }) => onError(error));
};

export const sawListApi = createApi({
    reducerPath: 'sawListApi',
    tagTypes: [ 'SawList' ],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.GATSBY_MICROSITE_API_URL,
        prepareHeaders: (headers, { getState }) => {
            const app = (getState() as RootState).app;
            headers.set('Accept-Retailer', prepareRetailerName(app.retailer));
            headers.set('Accept-Language', app.locale);
            headers.set('uuid', SawListHelper.getSawListUid(app.retailer));
        }
    }),
    endpoints: build => ({
        getSawList: build.query<ISawList, any>({
            query: () => 'sawlist',
            onQueryStarted: (...args) => onQueryStarted(
                ...args,
                response => {
                    const dispatch = args[1].dispatch;
                    dispatch(setSawList(response.data));
                    dispatch(setIsSawListLoading(false));
                }
            ),

            providesTags: result => result
                ? [ { type: 'SawList', id: result.uuid } ]
                : [ 'SawList' ]
        }),
        updateSawListItem: build.mutation({
            query: ({ id, body }) => ({
                url: `sawlist/${ id }`,
                method: 'PUT',
                body
            }),
            onQueryStarted,
            invalidatesTags: () => [ 'SawList' ]
        }),
        removeSawListItem: build.mutation({
            query: id => ({
                url: `sawlist/${ id }`,
                method: 'DELETE',
            }),
            onQueryStarted,
            invalidatesTags: () => [ 'SawList' ]
        }),
        copySawListItem: build.mutation<ISawListItem, string>({
            query: id => ({
                url: `/sawlist/copy/${ id }`,
                method: 'POST'
            }),
            onQueryStarted,
            invalidatesTags: () => [ 'SawList' ]
        }),
        addSawListItem: build.mutation<{ targetUrl?: string }, { quotation: string, options: Object }>({
            query: ({ quotation, options = {} }) => ({
                url: 'sawlist',
                method: 'POST',
                body: { quotation, options }
            }),
            onQueryStarted,
            invalidatesTags: () => [ 'SawList' ]
        })
    })
});

export const {
    useGetSawListQuery,
    useUpdateSawListItemMutation,
    useRemoveSawListItemMutation,
    useCopySawListItemMutation,
    useAddSawListItemMutation
} = sawListApi;