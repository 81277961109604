import { createTheme } from '@mui/material';
import BaseTheme, { createFontFamily } from './base/base';
import ThemeComponents from './base/components';
import DaxlinePro from '@fonts/DaxPro-Regular.woff2';
import DaxlineProBold from '@fonts/DaxPro-Bold.woff2';

let palette = {
    primary: {
        main: '#f28e00',
        light: '#f28e001a',
        lightTwo: '#fef4ea',
        dark: '#f28e00',
        darken: '#b96000'
    },
    secondary: {
        main: '#66dc80'
    },
    checklist: {
        main: '#F7901E',
        light: '#FDC689'
    },
    grey: {
        100: '#f7eddf'
    }
}

let configurator = {
    toggleMore: {
        text: {
            primary: palette.primary.main
        }
    },
    tooltip: {
        text: {
            primary: palette.primary.main
        }
    },
    checkbox: {
        active: palette.primary.main
    },
    cta: {
        main: {
            background: {
                primary: palette.primary.main
            },
            text: {
                primary: '#fff'
            },
        }
    },
    options: {
        button: {
            border: {
                active: palette.primary.main
            }
        },
        chip: {
            background: {
                active: palette.primary.lightTwo
            },
            border: {
                active: palette.primary.lightTwo
            },
            text: {
                active: '#000'
            }
        },
        radio: {
            border: {
                active: palette.primary.main
            },
            control: {
                active: palette.primary.main
            },
        },
        card: {
            border: {
                active: palette.primary.main
            }
        },
        check: {
            border: {
                active: palette.primary.main
            }
        },
        tile: {
            border: {
                active: palette.primary.main
            },
            mark: {
                background: {
                    primary: palette.primary.main,
                    active: palette.primary.main
                }
            },
            label: {
                background: {
                    primary: palette.primary.lightTwo,
                    active: palette.primary.lightTwo
                },
                text: {
                    primary: '#000',
                    active: '#000'
                }
            }
        },
        slider: {
            rail: {
                background: {
                    primary: palette.primary.lightTwo
                }
            },
            mark: {
                background: {
                    primary: palette.primary.main
                }
            },
            thumb: {
                background: {
                    primary: palette.primary.main
                }
            },
            valueLabel: {
                background: {
                    primary: palette.primary.main
                },
            }
        },
    }
}

let paletteTheme =  {
    ...palette,
    configurator: {
        ...configurator
    }
}

let candoTheme = {
    palette: {
        ...paletteTheme
    },
    typography: {
        ...createFontFamily('DaxlinePro'),
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'DaxlinePro';
                  src: url('${ DaxlinePro }') format('woff2');
                }
                @font-face {
                  font-family: 'DaxlinePro';
                  src: url('${ DaxlineProBold }') format('woff2');
                  font-weight: bold;
                }
                body { -webkit-overflow-scrolling:touch }
                ::-webkit-scrollbar { 
                    WebkitAppearance: none; 
                    width: 10px; 
                    height: 10px; 
                }
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 5px;
                    border: 2px solid #eeeeee;
                }
                ::-webkit-scrollbar-track {
                    background-color: #eeeeee;
                    border-radius: 10px;
                }
            `
        }
    }
}

let theme: any = createTheme(BaseTheme, candoTheme);

export default createTheme(theme, ThemeComponents(theme));
