import { isProductionEnv } from './commonHelper';

export const locales = [ 'nl_NL', 'nl_BE', 'fr_BE', 'fr_FR' ];

export const pageTypes = {
    Catalog: 'CatalogPage',
    PDP: 'ProductDetailPage',
    SawList: 'SawListPage',
    Content: 'ContentPage',
    SampleService: 'SampleService',
};

const defaultPages = (exclude: string[] = []) => [
    pageTypes.Catalog,
    pageTypes.PDP,
    pageTypes.SawList,
    pageTypes.Content,
    pageTypes.SampleService,
].filter(pageType => !exclude.includes(pageType));


interface Retailer {
    name: string;
    locales: string[];
    pages: string[];
    enabled: boolean;
}

interface Retailers {
    [name: string]: Retailer;
}

const initRetailer = (
    name: string,
    locales: string[],
    excludePages: string[] = [],
    enabled: boolean = true,
): Retailer => ({
    name,
    locales,
    pages: defaultPages(excludePages),
    enabled,
});

const timberRetailers = {
    Cando: initRetailer('Cando', [ 'nl-NL', 'nl-BE', 'fr-BE' ]),
    Gamma: initRetailer('Gamma', [ 'nl-NL' ]),
    GammaBe: initRetailer('GammaBe', [ 'nl-BE', 'fr-BE' ]),
    Karwei: initRetailer('Karwei', [ 'nl-NL' ]),
    Hubo: initRetailer('Hubo', [ 'nl-BE', 'fr-BE' ]),
    Forestea: initRetailer('Forestea', [ 'fr-FR' ]),
    Praxis: initRetailer('Praxis', [ 'nl-NL' ]),
    Brico: initRetailer('Brico', [ 'nl-BE', 'fr-BE' ]),
    Hornbach: initRetailer('Hornbach', [ 'nl-NL' ], [ pageTypes.SawList ]),
    NoName: initRetailer('NoName', [ 'nl-NL', 'nl-BE', 'fr-BE' ]),
    HuboNl: initRetailer('HuboNl', [ 'nl-NL' ]),
    Entrepot: initRetailer('Entrepot', [ 'fr-FR' ], [], !isProductionEnv),
};

const stairsRetailers = {
    GammaStairs: initRetailer('GammaStairs', timberRetailers.Gamma.locales),
    GammaBeStairs: initRetailer('GammaBeStairs', timberRetailers.GammaBe.locales),
    KarweiStairs: initRetailer('KarweiStairs', timberRetailers.Karwei.locales),
    PraxisStairs: initRetailer('PraxisStairs', timberRetailers.Praxis.locales),
    BricoStairs: initRetailer('BricoStairs', timberRetailers.Brico.locales),
    HornbachStairs: initRetailer('HornbachStairs', timberRetailers.Hornbach.locales, [ pageTypes.SawList ]),
    MrBricolageStairs: initRetailer('MrBricolageStairs', [ 'nl-BE', 'fr-BE' ]),
    HuboStairs: initRetailer('HuboStairs', timberRetailers.Hubo.locales),
};

const insectsRetailers = {
    HuboInsects: initRetailer('HuboInsects', timberRetailers.Hubo.locales),
    PraxisInsects: initRetailer('PraxisInsects', timberRetailers.Praxis.locales),
    BricoInsects: initRetailer('BricoInsects', timberRetailers.Brico.locales),
    HornbachInsects: initRetailer('HornbachInsects', timberRetailers.Hornbach.locales, [ pageTypes.SawList ]),
};

const cabinetsRetailers = {
    CandoCabinets: initRetailer('CandoCabinets', timberRetailers.Cando.locales),
    GammaCabinets: initRetailer('GammaCabinets', timberRetailers.Gamma.locales),
    KarweiCabinets: initRetailer('KarweiCabinets', timberRetailers.Karwei.locales),
    HuboCabinets: initRetailer('HuboCabinets', timberRetailers.Hubo.locales),
    PraxisCabinets: initRetailer('PraxisCabinets', timberRetailers.Praxis.locales),
    BricoCabinets: initRetailer('BricoCabinets', timberRetailers.Brico.locales),
    HornbachCabinets: initRetailer('HornbachCabinets', timberRetailers.Hornbach.locales, [ pageTypes.SawList ]),
};

const filterRetailersToBuild = (retailers: Retailers) => {
    if (isProductionEnv || !process.env.GATSBY_RETAILERS_TO_BUILD)
        return retailers;

    const enabled = process.env.GATSBY_RETAILERS_TO_BUILD.split(',');

    for (const name in retailers)
        retailers[name].enabled = enabled.includes(name);

    return retailers;
};

export const retailers = filterRetailersToBuild({
    ...timberRetailers,
    ...stairsRetailers,
    ...insectsRetailers,
    ...cabinetsRetailers
});

export const prepareRetailerName = (name: string) => name && name.replaceAll('-', '');

export const checkRetailerEnabled = (retailer: string) =>
    retailers[retailer]?.enabled ?? false;

export const checkRetailerHasPage = (retailer: string, pageType: string) =>
    retailers[retailer]?.pages.includes(pageType) ?? false;

export const checkRetailerHasLocale = (retailer: string, locale: string) =>
    retailers[retailer]?.locales.includes(locale) ?? false;
